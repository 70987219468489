<template>
    <v-container fluid>
        <v-form @submit.prevent="getPatients('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly && can(['patient'])" :to="{name: 'patient.create'}"
                   color="indigo" dark fab fixed top right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="8">
                                <v-subheader class="headline">{{ $t('search_patient') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly && can(['patient'])" cols="12" sm="4" class="text-right">
                                <v-btn :to="{name: 'patient.create'}" color="indigo" dark>{{$t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="8">
                                <ValidationProvider ref="patient" rules="min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="patient" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-account"
                                                  :label="$t('user_name')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="phone" rules="phone"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="phoneRaw" type="tel" v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-phone" :label="$t('phone')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          hide-details dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="filteredHeaders" :items="patients" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalPatients"
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              @page-count="pageCount = options.pageCount = Number($event)"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.phone="{ item }">
                        <div>{{formatPhoneNumber(item.phone)}}</div>
                    </template>
                    <template v-slot:item.active="{ item }">
                        {{ item.active ? $t('yes') : $t('no')}}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"
                                       @click="editUser(item)" icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"
                                       @click="deleteUser(item)" icon>
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('delete')}}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

import {mapGetters, mapActions} from "vuex"
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'

export default {
    name: "Patients",
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            fab: false,
            phoneRaw: null,
            patient: null,
            email: null,
            patients: [],
            active: 1,
            activeItems: [
                {
                    id: null,
                    name: this.$t('all')
                },
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],
            sortBy: "name",
            sortDir: true,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalPatients: 0,
            options: {},
            loading: false,
            headers: [
                {
                    text: this.$t('user_name'),
                    align: "left",
                    sortable: true,
                    value: "name"
                },
                {
                    text: this.$t('phone'),
                    sortable: true,
                    value: "phone"
                },
                {
                    text: this.$t('actions'),
                    align: "center",
                    value: 'action',
                    sortable: false,
                    width: 120,
                },
            ]
        }
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'phoneMask', 'formPatient']),
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
    },
    watch: {
        options: {
            handler() {
                this.getPatients('pagination')
            },
            deep: false
        }
    },
    methods: {
        ...mapActions(['setFormPatient']),
        editUser(item) {
            this.$router.push({
                name: 'patient.edit',
                params: {
                    id: item.uuid
                }
            })
        },
        async deleteUser(item) {
            if (confirm(this.$t('delete_patient'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`admin/patient/${item.uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('patient_has_been_deleted'))
                        this.getPatients()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('patient_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async getPatients(type) {
            console.log('page', this.page)
            this.loading = true
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options
            let params = {}
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc'
            }
            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage
            }

            if (this.patient) {
                params.patient = this.patient
            }
            if (this.phone) {
                params.phone = this.phone
            }
            if (this.email) {
                params.email = this.email
            }
            params.active = this.active

            if (type === undefined && page === 1 && this.formPatient) {
                params.patient = this.formPatient.patient
                params.email = this.formPatient.email
                params.phone = this.formPatient.phone
                params.active = this.formPatient.active
                params.sortDir = this.formPatient.sortDir
                params.sortBy = this.formPatient.sortBy
                params.perPage = this.formPatient.perPage
                params.page = this.formPatient.page
            } else {
                this.setFormPatient({
                    patient: params.patient,
                    email: params.email,
                    phone: params.phone,
                    active: params.active,
                    sortBy: params.sortBy,
                    sortDir: params.sortDir,
                    perPage: params.perPage,
                    page: params.page,
                })
            }

            await this.$http
                .get("admin/patient", {
                    params: params,
                })
                .then(res => {
                    this.patients = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalPatients = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.patients = []
                    this.totalPatients = 0
                    this.$toastr.error(this.$t('failed_to_get_list_patients'))
                })
                .finally(end => {
                    this.loading = false
                })
        }
    }
}
</script>
